import React from 'react'
import {Flex, PageWrapper, Paragraph, Title, Title4, RightSection, LeftSection, Row} from '../components/UI'
import SEO from '../components/seo'
import Layout from '../components/layout'
import styled from 'styled-components'
import UPCard from '../components/UPCard'
import person from '../images/person.jpg'
import history from '../images/history.jpg'
import carwash from '../images/carwash.jpg'
import PageHeader from '../components/PageHeader'
import { breakpoints } from '../utils/helper'
import Button from '../components/Button'
import { Link } from 'gatsby'

const HistoryBox = styled(Flex)`
    max-width: 1280px;
    width: 100%;
    padding: 60px 0;
    background: url(${history});
    background-size: cover;
    margin: 120px auto 0px;
    align-items: center;
    flex-direction: column;
    
    h1 {
        text-align: center;
        color: ${props => props.theme.colors.white};
    }
    
    p {
        color: ${props => props.theme.colors.white};
    }
    
    @media ${breakpoints.tablet} {
        height: 650px;
    }
`

const InnerRow = styled(Row)`
    max-width: 980px;
    width: 100%;
    
    p {
        width: 100%;
        padding-top: 24px;
       
        @media ${breakpoints.tablet} {
            width: 48%;    
            padding-top: 0;    
        }
    }     
`

const RedTitle = styled(Title4)`
    color: ${props => props.theme.colors.red};
    white-space: pre-wrap;
`

const Image = styled.img`
    width: 100%;
`

const PersonImage = styled(Image)`
    @media ${breakpoints.tablet} {
        margin-top: 42px;
    }
`

const StyledParagraph = styled(Paragraph)`
    padding-top: 24px;
    
    &.top_section {
        padding-top: 0;
    }
    
    &.bottom_section {
        padding-top: 0;
        margin: 0 0 42px;
    }
    
    @media ${breakpoints.tablet} {
        padding-top: 60px;
        
        &.top_section {
            padding-top: 42px;
        }
    }
`

const Section = styled(RightSection)`
    align-items: center;
`

const BottomRow = styled(Row)`
    margin-bottom: 90px;
`

const CarwashPage = ({location}) =>
    <Layout location={location}>
        <SEO title="Over UP | Alle informatie over het ontstaan van UP Carwash"
             description="We vertellen je alles over de wasstraat en het ontstaan van UP Carwash: dé wasstraat met aandacht voor elke auto."/>
        <PageHeader
            title="De volgende generatie carwash."
            image={carwash}
            backgroundColor={'#FF0900'}
            textColor={'#FFFFFF'}
            imageWidth="600px"
        />
        <PageWrapper>
            <Row>
                <LeftSection className="med">
                    <RedTitle>Waarom UP Carwash?</RedTitle>
                    <Title className="padded">Alles begint bij aandacht</Title>
                </LeftSection>
                <Section>
                    <RightSection className="text">
                        <StyledParagraph className="top_section">
                            We geven om <u>wat</u> we doen, en om <u>hoe</u> we dat doen. Daarom geven we jou en je auto auto de
                            aandacht die jullie verdienen. Met de allerbeste wasprogramma's voor een optimale
                            auto-reiniging. Altijd met ultiem gemak, snelheid én een goede prijs.
                            <br/><br/>
                            Aandacht en kwaliteit. That's what's UP.
                        </StyledParagraph>
                    </RightSection>
                </Section>
            </Row>
        </PageWrapper>
            <HistoryBox>
                <Title>Toonaangevend<br/>sinds 1964</Title>
                <InnerRow>
                    <Paragraph>Wij zijn een familiebedrijf, al vier generaties. In 1964 begonnen we in Beverwijk met
                        een autobedrijf en in 1989 openden we &eacute;&eacute;n van de eerste overdekte wasstraten
                        in Nederland. Nu in 2020 zijn wij de eerste die een franchiseformule voor de autowasindustrie
                        presenteren. </Paragraph>
                    <Paragraph>In UP Carwash komen vier generaties aan kennis, ervaring en liefde voor het autovak
                        samen. Dit resulteert in de ultieme wasbeurt, die we continu verder blijven ontwikkelen. Wat
                        echter nooit verandert is dat eerlijkheid, fatsoen en vriendelijkheid altijd voorop staan.
                        Aandacht is ons sleutelwoord.</Paragraph>
                </InnerRow>
            </HistoryBox>
        <PageWrapper>
            <BottomRow>
                <LeftSection className="med">
                    <RedTitle>Franchisenemer worden?</RedTitle>
                    <Title className="padded">It's UP to you!</Title>
                    <StyledParagraph className="bottom_section">
                        Sluit je aan bij de volgende generatie wasstraten en profiteer van toegang tot de nieuwste
                        technologie en een bewezen marketing- en groeistrategie. We hechten veel waarde aan een
                        gelijkwaardige, duurzame en persoonlijke relatie met onze franchisenemers en denken vanaf
                        het eerste moment graag met je mee.
                    </StyledParagraph>
                    <Link to="/franchise"><Button>Meer weten</Button></Link>
                </LeftSection>
                <Section>
                    <RightSection className="med">
                        <PersonImage src={person} alt="UP Carwash klant"/>
                    </RightSection>
                </Section>
            </BottomRow>
        </PageWrapper>
        <UPCard/>
    </Layout>

export default CarwashPage
